import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Main from '../src/Main.js'
import Terms from './pages/terms-and-conditions'

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/">
                    <Route index element={<Main />} />
                    <Route path="/regulamin" element={<Terms />} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}
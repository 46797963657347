import React from 'react'
import './terms-and-conditions.css'

export default function Terms() {
    return (
        <div className='container-tac'>
            <p className='main-text-tac'><span className='gradient-span-tac'>Regulamin usługi</span></p>
            <p className='terms-tac'>
                <p className='terms-bold-tac'>I. Definicje</p><br />
                <div className='each-subsection'>
                    Użyte w Regulaminie pojęcia oznaczają:
                    <br />
                    1. Klient – osoba fizyczna, osoba prawna lub jednostka organizacyjna nie będącą osobą prawną, której przepisy szczególne przyznają zdolność prawną, która dokonuje Zamówienia w ramach Sklepu.
                    <br />
                    2. Kodeks Cywilny – ustawa z dnia 23 kwietnia 1964 r. (Dz. U. Nr 16, poz. 93 ze zm.).
                    <br />
                    3. Regulamin – niniejszy Regulamin świadczenia usług drogą elektroniczną w ramach sklepu internetowego NEXONSTUDIO.
                    <br />
                    4. Sklep internetowy (NEXONSTUDIO.PL) – serwis internetowy dostępny pod NEXONSTUDIO.PL, za pośrednictwem którego Klient może w szczególności składać Zamówienia.
                    <br />
                    5. Towar – produkty prezentowane w Sklepie Internetowym.
                    <br />
                    6. Umowa sprzedaży – umowa sprzedaży Towarów w rozumieniu Kodeksu Cywilnego, zawarta pomiędzy NEXONSTUDIO.PL a Klientem, zawierana z wykorzystaniem serwisu internetowego Sklepu.
                    <br />
                    7. Ustawa o szczególnych warunkach sprzedaży konsumenckiej – ustawa z dnia 27 lipca 2002 r. o szczególnych warunkach sprzedaży konsumenckiej oraz o zmianie Kodeksu Cywilnego (Dz. U. Nr 141, poz. 1176 ze zm.).
                    <br />
                    8. Ustawa o świadczeniu usług drogą elektroniczną – ustawa z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną (Dz. U. Nr 144, poz. 1204 ze zm.).
                    <br />
                    9. Zamówienie – oświadczenie woli Klienta, zmierzające bezpośrednio do zawarcia Umowy sprzedaży, określające w szczególności rodzaj i liczbę Towaru.
                </div>


                <p className='terms-bold-tac'>II. Postanowienia ogólne</p>
                <div className='each-subsection'>
                    2.1. Niniejszy Regulamin określa zasady korzystania ze sklepu internetowego dostępnego pod NEXONSTUDIO.PL.
                    <br />
                    2.2. Niniejszy Regulamin został stworzony w celach testowych, prezentacyjnych i nie ma mocy prawnej.
                    <br />
                    2.3. Sklep internetowy, działający pod NEXONSTUDIO.PL, prowadzony jest przez NEXONSTUDIO.
                    <br />
                    2.4. Niniejszy Regulamin określa w szczególności:
                    <br />
                    a.) zasady dokonywania rejestracji i korzystania z konta w ramach sklepu internetowego.
                    <br />
                    b.) warunki i zasady dokonywania elektronicznej rezerwacji produktów dostępnych w ramach sklepu internetowego.
                    <br />
                    c.) warunki i zasady składania drogą elektroniczną Zamówień w ramach sklepu internetowego.
                    <br />
                    d.) zasady zawierania Umów sprzedaży z wykorzystaniem usług świadczonych w ramach Sklepu Internetowego.
                    <br />
                    2.5. Korzystanie ze sklepu internetowego jest możliwe pod warunkiem spełnienia przez system teleinformatyczny, z którego korzysta Klient następujących minimalnych wymagań technicznych.
                    2.6. W celu korzystania ze sklepu internetowego Klient powinien we własnym zakresie uzyskać dostęp do stanowiska komputerowego lub urządzenia końcowego, z dostępem do Internetu.
                    <br />
                    2.7. Zgodnie z obowiązującymi przepisami prawa zastrzega sobie możliwość ograniczenia świadczenia usług za pośrednictwem Sklepu internetowego do osób, które ukończyły wiek 18 lat. W takim przypadku potencjalni Klienci zostaną o powyższym powiadomieni.
                    <br />
                    2.8. Klienci mogą uzyskać dostęp do niniejszego Regulaminu w każdym czasie za pośrednictwem odsyłacza zamieszczonego na stronie głównej serwisu oraz pobrać go i sporządzić jego wydruk.
                    <br />
                    2.9. Informacje o Towarach podane na stronach internetowych Sklepu, w szczególności ich opisy, parametry techniczne i użytkowe oraz ceny, stanowią zaproszenie do zawarcia umowy, w rozumieniu art. 71 Kodeksu Cywilnego.
                </div>


                <p className='terms-bold-tac'>III. Zasady korzystania ze Sklepu Internetowego</p>
                <div className='each-subsection'>
                    3.1. Warunkiem rozpoczęcia korzystania ze Sklepu internetowego jest rejestracja w jego ramach.
                    <br />
                    3.2. Rejestracja następuje poprzez wypełnienie i zaakceptowanie formularza rejestracyjnego, udostępnianego na jednej ze stron Sklepu.
                    <br />
                    3.3. Warunkiem rejestracji jest wyrażenie zgody na treść Regulaminu oraz podanie danych osobowych oznaczonych jako obowiązkowe.
                    <br />
                    3.4. NEXONSTUDIO.PL może pozbawić Klienta prawa do korzystania ze Sklepu Internetowego, jak również może ograniczyć jego dostęp do części lub całości zasobów Sklepu Internetowego, ze skutkiem natychmiastowym, w przypadku naruszenia przez Klienta Regulaminu, a w szczególności, gdy Klient:
                    <br />
                    a.) podał w trakcie rejestracji w sklepie internetowym dane niezgodne z prawdą, niedokładne lub nieaktualne, wprowadzające w błąd lub naruszające prawa osób trzecich,
                    <br />
                    b.) dopuścił się za pośrednictwem sklepu internetowego naruszenia dóbr osobistych osób trzecich, w szczególności dóbr osobistych innych klientów sklepu internetowego,
                    <br />
                    c.) dopuści się innych zachowań, które zostaną uznane przez NEXONSTUDIO.PL za zachowania niezgodne z obowiązującymi przepisami prawa lub ogólnymi zasadami korzystania z sieci Internet lub godzące w dobre imię NEXONSTUDIO.PL.
                    <br />
                    3.5. Osoba, która została pozbawiona prawa do korzystania ze sklepu internetowego, nie może dokonać powtórnej rejestracji bez uprzedniej zgody NEXONSTUDIO.PL.
                    <br />
                    3.6. W celu zapewnienia bezpieczeństwa przekazu komunikatów i danych w związku ze świadczonymi w ramach Witryny usługami, Sklep internetowy podejmuje środki techniczne i organizacyjne odpowiednie do stopnia zagrożenia bezpieczeństwa świadczonych usług, w szczególności środki służące zapobieganiu pozyskiwania i modyfikacji przez osoby nieuprawnione danych osobowych przesyłanych w Internecie.
                    <br />
                    3.7. Klient zobowiązany jest w szczególności do:
                    <br />
                    a.) niedostarczania i nieprzekazywania treści zabronionych przez przepisy prawa, np. treści propagujących przemoc, zniesławiających lub naruszających dobra osobiste i inne prawa osób trzecich,
                    <br />
                    b.) korzystania ze Sklepu internetowego w sposób nie zakłócający jego funkcjonowania, w szczególności poprzez użycie określonego oprogramowania lub urządzeń,
                    <br />
                    c.) niepodejmowania działań takich jak: rozsyłanie lub umieszczanie w ramach Sklepu internetowego niezamówionej informacji handlowej (spam),
                    <br />
                    d.) korzystania ze Sklepu internetowego w sposób nieuciążliwy dla innych klientów oraz dla NEXONSTUDIO.PL,
                    <br />
                    e.) korzystania z wszelkich treści zamieszczonych w ramach Sklepu internetowego jedynie w zakresie własnego użytku osobistego,
                    <br />
                    f.) korzystania ze Sklepu internetowego w sposób zgodny z przepisami obowiązującego na terytorium Rzeczypospolitej Polskiej prawa, postanowieniami Regulaminu, a także z ogólnymi zasadami korzystania z sieci Internet.
                </div>





                <p className='terms-bold-tac'>IV. Dostawa</p>
                <div className='each-subsection'>
                    5.1. Dostawa Towarów jest ograniczona do obszaru Rzeczpospolitej Polskiej i odbywa się pod adres wskazany przez Klienta w trakcie składania Zamówienia.
                    <br />
                    5.2. Dostawa zamówionych Towarów odbywa się transportem własnym, przesyłką kurierską. Koszty dostawy wynoszą 15,99zł. Dodatkowo koszty dostawy zostaną wskazane w czasie składania Zamówienia.
                    <br />
                    5.3. Termin realizacji dostawy wynosi od do dni roboczych licząc od dnia wysłania przez Klienta Zamówienia.
                    <br />
                    5.4. Klienci mogą uzyskać dostęp do niniejszego Regulaminu w każdym czasie za pośrednictwem odsyłacza zamieszczonego na stronie głównej serwisu oraz pobrać go i sporządzić jego wydruk.
                    <br />
                    Utrwalenie, zabezpieczenie, udostępnienie oraz potwierdzenie Klientowi istotnych postanowień Umowy sprzedaży Towarów następuje poprzez przesłanie Klientowi na podany adres e-mail oraz poprzez dołączenie do przesyłki zawierającej Towar wydruku potwierdzenia, specyfikacji Zamówienia oraz faktury VAT.


                </div>
                <p className='terms-bold-tac'>V. Ceny i metody płatności</p>
                <div className='each-subsection'>
                    6.1. Ceny Towarów podawane są w złotych polskich i zawierają wszystkie składniki, w tym podatek VAT.
                    <br />
                    6.2. Klient ma możliwość uiszczenia ceny:
                    <br />
                    a.) przelewem na numer konta bankowego,
                    <br />
                    b.) płatnością w systemie
                    <br />
                    c.) zapłata kartą płatniczą
                    <br />
                </div>

                <p className='terms-bold-tac'>VI. Postanowienia końcowe</p>
                <div className='each-subsection'>
                    10.1. Rozstrzyganie ewentualnych sporów powstałych pomiędzy NEXONSTUDIO.PL a Klientem, który jest konsumentem w rozumieniu art. 221 Kodeksu Cywilnego, zostaje poddane sądom właściwym zgodnie z postanowieniami właściwych przepisów Kodeksu postępowania cywilnego.
                    <br />
                    10.2. Rozstrzyganie ewentualnych sporów powstałych pomiędzy NEXONSTUDIO.PL a Klientem, który nie jest konsumentem w rozumieniu art. 221 Kodeksu Cywilnego Kodeksu Cywilnego.
                    <br />
                    10.3. W sprawach nieuregulowanych w niniejszym Regulaminie mają zastosowanie przepisy Kodeksu cywilnego, przepisy Ustawy o świadczeniu usług drogą elektroniczną oraz inne właściwe przepisy prawa polskiego.
                    <br />
                    10.4. Regulamin ma charakter testowy i podglądowy. Nie należy traktować poważnie, nie ma żadnej mocy prawnej.</div></p>
            <div className='footer-tac'>
                <p className='sub-text-tac'>Wróć na stronę wyboru <a className="a__links" href="/">planów cenowych</a> lub witryny <a className="a__links" href="https://nexonstudio.pl/">nexonstudio.pl</a>.</p>
            </div>
        </div >
    )
}
import './PricingTab.css';
import pricesvg from './assets/price.svg';
import packagesvg from './assets/video.svg'


export default function PricingTab (props,color,price,text1,icon1,text2,icon2,text3,icon3,text4,icon4,text5,icon5,text6,icon6,text7,icon7,text8,icon8,text9,icon9) {
    return (
        <div className='PricingTab'>
            <h3>{props.topic}</h3>
            <img src={packagesvg} className='logo-tag' alt='Logo'/>
            <div className={`pricing-card-price`}><img src={pricesvg} alt='Price' />{props.price}</div>
            <div className='buttons'>
            <a href="#" className={`price-card-button ${props.color}`}>Kup teraz!</a>
            </div>
            <div className='divider'></div>
            <ul className='benefits'>
                <li className={props.icon1}>{props.text1}</li>
                <li className={props.icon2}>{props.text2}</li>
                <li className={props.icon3}>{props.text3}</li>
                <li className={props.icon4}>{props.text4}</li>
                <li className={props.icon5}>{props.text5}</li>
                <li className={props.icon6}>{props.text6}</li>
                <li className={props.icon7}>{props.text7}</li>
                <li className={props.icon8}>{props.text8}</li>
                <li className={props.icon9}>{props.text9}</li>
            </ul>
        </div>
    )
}

import './App.css';
import PricingTab from './PricingTab.js';
// import Fade from 'react-reveal/Fade';

function Main() {
  return (
    <div className="App">
      <div className='container'>

        <p className='main-text'>Wybierz swój <span className='gradient-span'>Plan Cenowy</span></p>
        <p className='sub-text'>Wszystkie ceny są cenami za <span className='gradient-span'> jednorazowy zakup.</span> Płatność nie jest płatnością subksrypcyjną.</p>
        <p className='sub-text'>Płacisz raz! <span className='gradient-span'> Korzystasz ile chcesz!</span></p>


        <div className='sections'>
          <div className='tabs-section'>
            {/* // Makes showing this containter by a map function */}
            <PricingTab
              price="35.99 zł"
              color=""
              topic="Pakiet Light"
              text1="Bezpłatne pobranie i instalacja"
              icon1="good"
              text2="Transfer 5GB dziennie"
              icon2="good"
              text3="Pobieranie w jakości HD"
              icon3="good"
              text4="Limit 5 pobrań na miesiąc"
              icon4="good"
              text5="Limit 10 pobrań na miesiąc"
              icon5="cancel"
              text6="Limit 50 pobrań na miesiąc"
              icon6="cancel"
              text7="Pobieranie w jakości FULL HD i 4K"
              icon7="cancel"
              text8="Wsparcie techniczne"
              icon8="cancel"
            />
            <PricingTab
              price="49.99 zł"
              color="silver"
              topic="Pakiet Silver"
              text1="Bezpłatne pobranie i instalacja"
              icon1="good"
              text2="Transfer 50GB dziennie"
              icon2="good"
              text3="Limit 5 pobrań na miesiąc"
              icon3="good"
              text4="Limit 10 pobrań na miesiąc"
              icon4="good"
              text5="Limit 50 pobrań na miesiąc"
              icon5="good"
              text6="Pobieranie w jakości FULL HD"
              icon6="good"
              text7="Pobieranie w jakości 4K"
              icon7="cancel"
              text8="Wsparcie techniczne"
              icon8="cancel"
            />
            <PricingTab
              price="79.99 zł"
              color="gold"
              topic="Pakiet Gold"
              text1="Bezpłatne pobranie i instalacja"
              icon1="good"
              text2="Nielimitowany transfer dzienny"
              icon2="good"
              text3="Limit 5 pobrań na miesiąc"
              icon3="good"
              text4="Limit 10 pobrań na miesiąc"
              icon4="good"
              text5="Limit 50 pobrań na miesiąc"
              icon5="good"
              text6="Pobieranie w jakości 4K"
              icon6="good"
              text7="Limit 1000 pobrań na miesiąc"
              icon7="extra"
              text8="Wsparcie techniczne 24/7"
              icon8="extra"
            />
          </div>
        </div>
        <div className='footer'>
          <p className='sub-text'>Dokonując zakupu potwierdzasz <a className="a__links" href="/regulamin">regulamin usługi</a> oraz witryny <a className="a__links" href="https://nexonstudio.pl/">nexonstudio.pl</a>. <a className="a__links" href="/polityka">Polityka prywatności</a></p>
        </div>

      </div>
    </div >
  );
}

export default Main;
